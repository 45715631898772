import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hr-tile" }
const _hoisted_2 = { class: "breadcrumbs" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = { class: "rules hr-tile greeble-tr" }
const _hoisted_5 = { class: "title is-4" }
const _hoisted_6 = { class: "subtitle is-4" }
const _hoisted_7 = {
  key: 0,
  class: "stat-category"
}
const _hoisted_8 = {
  key: 1,
  class: "stat-category"
}
const _hoisted_9 = { class: "stat-cards" }
const _hoisted_10 = { class: "stat" }
const _hoisted_11 = { class: "stat-number" }
const _hoisted_12 = { class: "stat" }
const _hoisted_13 = { class: "stat-number" }
const _hoisted_14 = { class: "stat" }
const _hoisted_15 = { class: "trophies" }
const _hoisted_16 = { class: "trophy-container" }
const _hoisted_17 = { class: "trophy-container" }
const _hoisted_18 = { class: "trophy-container" }
const _hoisted_19 = { class: "stat" }
const _hoisted_20 = { class: "trophies" }
const _hoisted_21 = { class: "trophy-container" }
const _hoisted_22 = { class: "trophy-container" }
const _hoisted_23 = { class: "trophy-container" }
const _hoisted_24 = { class: "stat" }
const _hoisted_25 = { class: "stat-number" }
const _hoisted_26 = {
  key: 2,
  class: "stat-category"
}
const _hoisted_27 = { class: "stat-cards" }
const _hoisted_28 = { class: "priorities-grid" }
const _hoisted_29 = {
  key: 0,
  class: "game-card stat"
}
const _hoisted_30 = { class: "stat-number" }
const _hoisted_31 = { class: "img-title" }
const _hoisted_32 = ["src"]
const _hoisted_33 = {
  key: 1,
  class: "il-card stat"
}
const _hoisted_34 = { class: "stat-number" }
const _hoisted_35 = { class: "img-title" }
const _hoisted_36 = ["src"]
const _hoisted_37 = {
  key: 2,
  class: "difficulty-card stat"
}
const _hoisted_38 = { class: "stat-number" }
const _hoisted_39 = { class: "stat-number" }
const _hoisted_40 = ["src"]
const _hoisted_41 = { class: "stat-cards" }
const _hoisted_42 = {
  key: 0,
  class: "stat"
}
const _hoisted_43 = { class: "stat-number" }
const _hoisted_44 = { class: "stat-number" }
const _hoisted_45 = {
  key: 1,
  class: "stat"
}
const _hoisted_46 = { class: "stat-number" }
const _hoisted_47 = { class: "stat-number" }
const _hoisted_48 = {
  key: 2,
  class: "stat"
}
const _hoisted_49 = { class: "stat-number" }
const _hoisted_50 = { class: "stat-number" }
const _hoisted_51 = {
  key: 3,
  class: "stat"
}
const _hoisted_52 = { class: "stat-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: '/' }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("li", { class: "breadcrumb-item" }, [
          _createElementVNode("span", null, "Post Year Carnage Report")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h4", _hoisted_5, "Post Year Carnage Report - " + _toDisplayString(_ctx.year), 1),
      _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.pycr?.User?.Username), 1),
      (_ctx.pycr?.SubmittedRuns == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[2] || (_cache[2] = [
            _createElementVNode("h5", null, "Cryo", -1),
            _createElementVNode("small", null, null, -1),
            _createTextVNode(" Looks like you were in cryo for the year and didn't have any Runs :( ")
          ])))
        : _createCommentVNode("", true),
      (_ctx.pycr?.SubmittedRuns)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[3] || (_cache[3] = _createElementVNode("label", null, "Runs Submitted", -1)),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.pycr.SubmittedRuns), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _cache[4] || (_cache[4] = _createElementVNode("label", null, "Total Timesave", -1)),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.pycr.TotalTimeSave), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[8] || (_cache[8] = _createElementVNode("label", null, "Trophie Runs", -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, [
                    _cache[5] || (_cache[5] = _createElementVNode("i", { class: "trophy in-line rank-1" }, null, -1)),
                    _createTextVNode(" × " + _toDisplayString(_ctx.pycr.TotalGoldTrophiesGained), 1)
                  ]),
                  _createElementVNode("span", _hoisted_17, [
                    _cache[6] || (_cache[6] = _createElementVNode("i", { class: "trophy in-line rank-2" }, null, -1)),
                    _createTextVNode(" × " + _toDisplayString(_ctx.pycr.TotalSilverTrophiesGained), 1)
                  ]),
                  _createElementVNode("span", _hoisted_18, [
                    _cache[7] || (_cache[7] = _createElementVNode("i", { class: "trophy in-line rank-3" }, null, -1)),
                    _createTextVNode(" × " + _toDisplayString(_ctx.pycr.TotalBronzeTrophiesGained), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[12] || (_cache[12] = _createElementVNode("label", null, "Trophie Runs Lost", -1)),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("span", _hoisted_21, [
                    _cache[9] || (_cache[9] = _createElementVNode("i", { class: "trophy in-line rank-1" }, null, -1)),
                    _createTextVNode(" × " + _toDisplayString(_ctx.pycr.TotalGoldTrophiesLost), 1)
                  ]),
                  _createElementVNode("span", _hoisted_22, [
                    _cache[10] || (_cache[10] = _createElementVNode("i", { class: "trophy in-line rank-2" }, null, -1)),
                    _createTextVNode(" × " + _toDisplayString(_ctx.pycr.TotalSilverTrophiesLost), 1)
                  ]),
                  _createElementVNode("span", _hoisted_23, [
                    _cache[11] || (_cache[11] = _createElementVNode("i", { class: "trophy in-line rank-3" }, null, -1)),
                    _createTextVNode(" × " + _toDisplayString(_ctx.pycr.TotalBronzeTrophiesLost), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _cache[13] || (_cache[13] = _createElementVNode("label", null, "Points Claimed", -1)),
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.pycr.PointsClaimed), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.pycr?.SubmittedRuns)
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                (_ctx.pycr.MostSubmittedGameRuns)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                      _cache[14] || (_cache[14] = _createElementVNode("label", null, "Most Active Game", -1)),
                      _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.pycr.MostSubmittedGameRuns) + " Runs Submitted", 1),
                      _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.gameName(_ctx.pycr.MostSubmittedGameId!)), 1),
                      _createElementVNode("img", {
                        src: _ctx.gameImage(_ctx.pycr.MostSubmittedGameId!)
                      }, null, 8, _hoisted_32)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.pycr.MostSubmittedILRuns)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                      _cache[15] || (_cache[15] = _createElementVNode("label", null, "Most Submitted Segment", -1)),
                      _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.pycr.MostSubmittedILRuns) + " Runs Submitted", 1),
                      _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.levelName(_ctx.pycr.MostSubmittedIL!)), 1),
                      _createElementVNode("img", {
                        src: _ctx.levelImage(_ctx.pycr.MostSubmittedIL!)
                      }, null, 8, _hoisted_36)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.pycr.MostSubmittedDifficultyRuns)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                      _cache[16] || (_cache[16] = _createElementVNode("label", null, "Most Active Difficulty", -1)),
                      _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.pycr.MostSubmittedDifficultyRuns) + " Runs Submitted", 1),
                      _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.diffName(_ctx.pycr.MostSubmittedDifficultyId!)), 1),
                      _createElementVNode("img", {
                        src: _ctx.diffImage(_ctx.pycr.MostSubmittedDifficultyId!)
                      }, null, 8, _hoisted_40)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_41, [
              (_ctx.pycr.MostSubmittedMainCategoryRuns)
                ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                    _cache[17] || (_cache[17] = _createElementVNode("label", null, "Most Active Category", -1)),
                    _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.pycr.MostSubmittedMainCategoryRuns), 1),
                    _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.catName(_ctx.pycr.MostSubmittedMainCategoryId!)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.pycr.MostSubmittedExtraCategoryRuns)
                ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                    _cache[18] || (_cache[18] = _createElementVNode("label", null, "Most Active Extra Category", -1)),
                    _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.pycr.MostSubmittedExtraCategoryRuns), 1),
                    _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.catName(_ctx.pycr.MostSubmittedExtraCategoryId!)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.pycr.MostFrequentCoopPartnerRuns)
                ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                    _cache[19] || (_cache[19] = _createElementVNode("label", null, "Most frequent coop partner", -1)),
                    _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.pycr.MostFrequentCoopPartnerRuns), 1),
                    _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.pycr.MostFrequentCoopPartner), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.pycr.RivalBopsCount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                    _createElementVNode("label", null, "You bopped your rival " + _toDisplayString(_ctx.pycr.RivalBopsCount) + " times", 1),
                    _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.pycr.RivalUsername), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}