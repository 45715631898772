import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["placeholder"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "small muted"
}
const _hoisted_4 = {
  key: 2,
  class: "small muted"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "typeahead",
    onFocusout: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleFocusOut && _ctx.handleFocusOut(...args))),
    tabindex: "0"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["input-container", {'loading': _ctx.loading}])
    }, [
      (!_ctx.value)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            ref: "typeaheadInput",
            class: _normalizeClass(["input", {required: _ctx.required}]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rawInput) = $event)),
            onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.queryFocus && _ctx.queryFocus(...args))),
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.inputChanged && _ctx.inputChanged(...args))),
            placeholder: _ctx.placeholder
          }, null, 42, _hoisted_1)), [
            [_vModelText, _ctx.rawInput]
          ])
        : _createCommentVNode("", true),
      (_ctx.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "selected-item",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearSelected && _ctx.clearSelected(...args)))
          }, [
            (_ctx.value?.Title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.displayValue), 1))
              : _createCommentVNode("", true),
            (_ctx.value?.Url)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.value.Url), 1))
              : (_ctx.rawInput)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.rawInput), 1))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showResults)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "expanded-container",
          style: _normalizeStyle(_ctx.expandedStyle)
        }, [
          _cache[5] || (_cache[5] = _createElementVNode("h4", null, "Your Recent Uploads", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentVideos, (vid) => {
            return (_openBlock(), _createElementBlock("p", {
              class: "item",
              role: "button",
              tabindex: "0",
              key: vid.Url,
              onClick: ($event: any) => (_ctx.select(vid))
            }, [
              _createElementVNode("div", null, _toDisplayString(vid.Title), 1),
              _createElementVNode("img", {
                src: vid.ThumbnailUrl
              }, null, 8, _hoisted_6)
            ], 8, _hoisted_5))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ], 32))
}