/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostYearCarnageReport } from '../models/PostYearCarnageReport';
import { request as __request } from '../core/request';

export class PostYearCarnageReportService {

    /**
     * @param year 
     * @param userIdOverride 
     * @returns PostYearCarnageReport OK
     * @throws ApiError
     */
    public static async getYearReport(
year: number,
userIdOverride?: string,
): Promise<PostYearCarnageReport> {
        const result = await __request({
            method: 'GET',
            path: `/api/PostYearCarnageReport/${year}`,
            query: {
                'userIdOverride': userIdOverride,
            },
        });
        return result.body;
    }

}