<template>
    <div class="hr-tile">
        <ul class="breadcrumbs">
            <li class="breadcrumb-item">
                <router-link :to="'/'">Home</router-link>
            </li>
            <li class="breadcrumb-item">
                <span>Post Year Carnage Report</span>
            </li>
        </ul>
    </div>
    <div class="rules hr-tile greeble-tr">
        <h4 class="title is-4">Post Year Carnage Report - {{year}}</h4>
        <h5 class="subtitle is-4">{{ pycr?.User?.Username }}</h5>

        <div class="stat-category" v-if="pycr?.SubmittedRuns == 0">
            <h5>Cryo</h5>
            <small></small>

            Looks like you were in cryo for the year and didn't have any Runs :(
        </div>

        <div class="stat-category" v-if="pycr?.SubmittedRuns">

            <div class="stat-cards">
                <div class="stat">
                    <label>Runs Submitted</label>
                    <div class="stat-number">{{ pycr.SubmittedRuns }}</div>
                </div>
                <div class="stat">
                    <label>Total Timesave</label>
                    <div class="stat-number">{{ pycr.TotalTimeSave }}</div>
                </div>
                <div class="stat">
                    <label>Trophie Runs</label>
                    <div class="trophies">
                        <span class="trophy-container"><i class="trophy in-line rank-1"></i> &times; {{ pycr.TotalGoldTrophiesGained }}  </span>
                        <span class="trophy-container"><i class="trophy in-line rank-2"></i> &times; {{ pycr.TotalSilverTrophiesGained }}</span>
                        <span class="trophy-container"><i class="trophy in-line rank-3"></i> &times; {{ pycr.TotalBronzeTrophiesGained }}</span>
                    </div>
                </div>
                <div class="stat">
                    <label>Trophie Runs Lost</label>
                    <div class="trophies">
                        <span class="trophy-container"><i class="trophy in-line rank-1"></i> &times; {{   pycr.TotalGoldTrophiesLost }}  </span>
                        <span class="trophy-container"><i class="trophy in-line rank-2"></i> &times; {{ pycr.TotalSilverTrophiesLost }}</span>
                        <span class="trophy-container"><i class="trophy in-line rank-3"></i> &times; {{ pycr.TotalBronzeTrophiesLost }}</span>
                    </div>
                    
                </div>
                <div class="stat">
                    <label>Points Claimed</label>
                    
                    <div class="stat-number">{{ pycr.PointsClaimed }}</div>
                </div>
            </div>
        </div>

        <div class="stat-category" v-if="pycr?.SubmittedRuns">
            <div class="stat-cards">
                <div class="priorities-grid">
                    <div class="game-card stat" v-if="pycr.MostSubmittedGameRuns">
                        <label>Most Active Game</label>
                        
                        <div class="stat-number">{{ pycr.MostSubmittedGameRuns }} Runs Submitted</div>
                        
                        <span class="img-title">{{ gameName(pycr.MostSubmittedGameId!) }}</span>
                        <img :src="gameImage(pycr.MostSubmittedGameId!)" />
                    </div>


                    <div class="il-card stat" v-if="pycr.MostSubmittedILRuns">
                        <label>Most Submitted Segment</label>

                        <div class="stat-number">{{ pycr.MostSubmittedILRuns }} Runs Submitted</div>
                        
                        <span class="img-title">{{ levelName(pycr.MostSubmittedIL!) }}</span>
                        <img :src="levelImage(pycr.MostSubmittedIL!)" />
                    </div>
                    <div class="difficulty-card stat" v-if="pycr.MostSubmittedDifficultyRuns">
                        <label>Most Active Difficulty</label>
                        <div class="stat-number">{{ pycr.MostSubmittedDifficultyRuns }} Runs Submitted</div>
                        <div class="stat-number">{{ diffName(pycr.MostSubmittedDifficultyId!) }}</div>
                        <img :src="diffImage(pycr.MostSubmittedDifficultyId!)"/>
                    </div>
                </div>
                
                
                
            </div>
            <div class="stat-cards">
                <div class="stat" v-if="pycr.MostSubmittedMainCategoryRuns">
                    <label>Most Active Category</label>
                    <div class="stat-number">{{ pycr.MostSubmittedMainCategoryRuns }}</div>
                    <div class="stat-number">{{ catName(pycr.MostSubmittedMainCategoryId!) }}</div>
                </div>
                <div class="stat" v-if="pycr.MostSubmittedExtraCategoryRuns">
                    <label>Most Active Extra Category</label>
                    <div class="stat-number">{{ pycr.MostSubmittedExtraCategoryRuns }}</div>
                    <div class="stat-number">{{ catName(pycr.MostSubmittedExtraCategoryId!) }}</div>
                </div>
                <div class="stat" v-if="pycr.MostFrequentCoopPartnerRuns">
                    <label>Most frequent coop partner</label>
                    <div class="stat-number">{{ pycr.MostFrequentCoopPartnerRuns }}</div>
                    <div class="stat-number">{{ pycr.MostFrequentCoopPartner }}</div>
                </div>

                <div class="stat" v-if="pycr.RivalBopsCount">
                    <label>You bopped your rival {{ pycr.RivalBopsCount }} times</label>
                    <div class="stat-number">{{ pycr.RivalUsername }}</div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script lang="ts">

    import { PostYearCarnageReport, PostYearCarnageReportService } from "@/api";
    import { GameStore } from "@/store/games";
    import { Component, Vue } from "vue-facing-decorator";

    @Component({
        components: {
            
        }
    })
    export default class PostYearCarnageReportPage extends Vue {
        public year: string = "";
        public pycr: PostYearCarnageReport | null = null;
        public gameStore : GameStore | undefined;

        async created() {
            this.year = this.$route.params["year"] as string;
            let userIdOverride = this.$route.params["userIdOverride"] as string;

            this.gameStore = await GameStore.get();
            this.pycr = await PostYearCarnageReportService.getYearReport(parseInt(this.year), userIdOverride);
        }

        public gameImage(id: string) {
            return this.imageRoot + this.imageMetadata.Games.filter(g => g.Id == id)[0].ImageName;
        }

        public levelImage(id: string) {
            for(let g of this.imageMetadata.Games) {
                for(let s of g.RunnableSegments) {
                    if(s.Id == id) {
                        return this.imageRoot + s.ImageName;
                    }
                }
            }
        }

        public levelName(id: string) {
            for(let g of this.imageMetadata.Games) {
                for(let s of g.RunnableSegments) {
                    if(s.Id == id) {
                        return s.Name;
                    }
                }
            } 

            return "??";
        }

        public gameName(id: string) {
            return this.gameStore!.findGame(id).Name;
        }

        public diffImage(id: string) {
            for(let g of this.gameStore!.games) {
                for(let d of g.Difficulties ?? []) {
                    if(d.Id == id) {
                        return d.IconPath;
                    }
                }
            }
        }

        public diffName(id: string) {
            for(let g of this.gameStore!.games) {
                for(let d of g.Difficulties ?? []) {
                    if(d.Id == id) {
                        return d.Name;
                    }
                }
            }
        }
         
        public catName(id: string) {
            for(let g of this.gameStore!.games) {
                for(let d of g.Categories ?? []) {
                    if(d.Id == id) {
                        return d.Name;
                    }
                }
            }
        }
        
        public imageRoot = "/img/pycr-images/";

        public imageMetadata =
            {
                "Games": [{
                    "Name": "Halo 3: ODST",
                    "UrlStub": "odst",
                    "RunnableSegments": [{
                        "Name": "Prepare to Drop",
                        "UrlStub": "ptd",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000000",
                        "ImageName": "H3ODST_C01_PREPARETODROP.png"
                    }, {
                        "Name": "Tayari Plaza",
                        "UrlStub": "tayari",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000001",
                        "ImageName": "H3ODST_C03_TAYARIPLAZA.png"
                    }, {
                        "Name": "Uplift Reserve",
                        "UrlStub": "uplift",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000002",
                        "ImageName": "H3ODST_C04_UPLIFTRESERVE.png"
                    }, {
                        "Name": "Kizingo Blvd.",
                        "UrlStub": "kizingo",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000003",
                        "ImageName": "H3ODST_C05_KIZINGOBOULEVARD.png"
                    }, {
                        "Name": "ONI Alpha Site",
                        "UrlStub": "oni",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000004",
                        "ImageName": "H3ODST_C06_ONIALPHASITE.png"
                    }, {
                        "Name": "NMPD HQ",
                        "UrlStub": "nmpd",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000005",
                        "ImageName": "H3ODST_C07_NMPDHQ.png"
                    }, {
                        "Name": "Kikowani Station",
                        "UrlStub": "kiko",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000006",
                        "ImageName": "H3ODST_C08_KIKOWANISTATION.png"
                    }, {
                        "Name": "Data Hive",
                        "UrlStub": "dh",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000007",
                        "ImageName": "H3ODST_C09_DATAHIVE.png"
                    }, {
                        "Name": "Coastal Highway",
                        "UrlStub": "coastal",
                        "Id": "4a100d57-0000-3000-8000-1ebe10000008",
                        "ImageName": "H3ODST_C10_COASTALHIGHWAY.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "4a100d57-0000-3000-8000-1ebe100000f9",
                        "ImageName": "H3ODST_FG.png"
                    }, {
                        "Name": "Streets: Remote Detonator",
                        "UrlStub": "remote",
                        "Id": "4a75fe0c-9f5e-4073-9c83-ce0b6278a16d",
                        "ImageName": "H3ODST_C02_MOMBASASTREETS.png"
                    }, {
                        "Name": "Streets: Drone Optic",
                        "UrlStub": "optic",
                        "Id": "4b80ce2f-cd8a-49b1-91f7-24b7adc66b32",
                        "ImageName": "H3ODST_C02_MOMBASASTREETS.png"
                    }, {
                        "Name": "Streets: Biofoam Canister",
                        "UrlStub": "bio",
                        "Id": "89a7fdbc-c3a7-42bf-9697-aee0df27ffe3",
                        "ImageName": "H3ODST_C02_MOMBASASTREETS.png"
                    }, {
                        "Name": "Streets: Data Hive",
                        "UrlStub": "data",
                        "Id": "9c8acdc9-4c69-4c6f-90b9-15934299351b",
                        "ImageName": "H3ODST_C02_MOMBASASTREETS.png"
                    }, {
                        "Name": "Streets: Gauss Turret",
                        "UrlStub": "gauss",
                        "Id": "a8fdf636-940d-4998-8b25-64142ff533d3",
                        "ImageName": "H3ODST_C02_MOMBASASTREETS.png"
                    }, {
                        "Name": "Streets: Sniper Rifle",
                        "UrlStub": "sniper",
                        "Id": "f7efd6a3-44bb-4df4-9208-c332a4d2b98c",
                        "ImageName": "H3ODST_C02_MOMBASASTREETS.png"
                    }
                    ],
                    "Id": "4a100d57-0000-3000-8000-000000000000",
                    "ImageName": "HALO3ODST.png"
                }, {
                    "Name": "Halo Infinite",
                    "UrlStub": "infinite",
                    "RunnableSegments": [{
                        "Name": "Excavation Site",
                        "UrlStub": "Excavation Site",
                        "Id": "12485245-5cd8-466b-beb7-8ba1cd305f1f",
                        "ImageName": "HINF_EXCAVATION.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "13d054e5-56a1-4ec6-8e47-8402b9ff1423",
                        "ImageName": "HINF_FG.png"
                    }, {
                        "Name": "Spire",
                        "UrlStub": "Spire",
                        "Id": "143f36c5-80f6-4167-a898-dfd912984b00",
                        "ImageName": "HINF_SPIRE.png"
                    }, {
                        "Name": "House of Reckoning",
                        "UrlStub": "House of Reckoning",
                        "Id": "3a884ba7-27d8-4955-a56e-07710b9773ac",
                        "ImageName": "HINF_HOR.png"
                    }, {
                        "Name": "Conservatory",
                        "UrlStub": "Conservatory",
                        "Id": "3dac7d28-f203-4574-8e56-83b0339b333e",
                        "ImageName": "HINF_CONSERVATORY.png"
                    }, {
                        "Name": "Movement Course NG+",
                        "UrlStub": "movementng",
                        "Id": "3ec2e476-b9f0-4b6c-8398-481a2dbd1cf2",
                        "ImageName": "HINF_ACADEMY.png"
                    }, {
                        "Name": "The Command Spire",
                        "UrlStub": "The Command Spire",
                        "Id": "54a24afb-8afb-4136-ab52-5ae9a01a4017",
                        "ImageName": "HINF_COMMANDSPIRE.png"
                    }, {
                        "Name": "Warship Gbraakon",
                        "UrlStub": "Warship Gbraakon",
                        "Id": "567a121c-5328-4c6d-b596-8b3ee419e55a",
                        "ImageName": "HINF_GBRAAKON.png"
                    }, {
                        "Name": "Outpost Tremonius",
                        "UrlStub": "Outpost Tremonius",
                        "Id": "77918001-14e2-4e81-92c7-f721cffaddf9",
                        "ImageName": "HINF_OUTPOST.png"
                    }, {
                        "Name": "The Sequence",
                        "UrlStub": "The Sequence",
                        "Id": "835f39e3-0744-4a74-900b-108659ffd550",
                        "ImageName": "HINF_SEQUENCE.png"
                    }, {
                        "Name": "Nexus",
                        "UrlStub": "Nexus",
                        "Id": "9d3995e1-4ffd-4f96-80be-b2676b17a831",
                        "ImageName": "HINF_NEXUS.png"
                    }, {
                        "Name": "Foundation",
                        "UrlStub": "Foundation",
                        "Id": "af083e46-2ab7-420f-baa5-7f70899583bf",
                        "ImageName": "HINF_C01_FOUNDATION.png"
                    }, {
                        "Name": "The Road",
                        "UrlStub": "The Road",
                        "Id": "b4968b59-14db-4a30-8508-2325841b162e",
                        "ImageName": "HINF_ROAD.png"
                    }, {
                        "Name": "Movement Course",
                        "UrlStub": "movement",
                        "Id": "ba7a5aa2-cbbd-4d90-9725-0366b90b7354",
                        "ImageName": "HINF_ACADEMY.png"
                    }, {
                        "Name": "Pelican Down",
                        "UrlStub": "Pelican Down",
                        "Id": "c48ccfc4-b009-4659-bcb3-5c8b5c5c01e9",
                        "ImageName": "HINF_PELICANDOWN.png"
                    }, {
                        "Name": "Repository",
                        "UrlStub": "Repository",
                        "Id": "ce6f2eaf-8e6a-4a02-9a4f-a5431c93a1b5",
                        "ImageName": "HINF_REPOSITORY.png"
                    }, {
                        "Name": "Tutorial",
                        "UrlStub": "tutorial",
                        "Id": "e1189f29-1b39-464d-a5d5-a48fef074183",
                        "ImageName": "HINF_ACADEMY.png"
                    }, {
                        "Name": "Recovery",
                        "UrlStub": "Recovery",
                        "Id": "ecee8a82-f945-447c-aac5-2499cea35e96",
                        "ImageName": "HINF_RECOVERY.png"
                    }, {
                        "Name": "Silent Auditorium",
                        "UrlStub": "Silent Auditorium",
                        "Id": "f0da8bd9-2990-4900-9946-54a177bd6055",
                        "ImageName": "HINF_SA.png"
                    }
                    ],
                    "Id": "4a1019f1-0000-3000-8000-000000000000",
                    "ImageName": "Halo_Infinite.png"
                }, {
                    "Name": "Halo 2",
                    "UrlStub": "h2",
                    "RunnableSegments": [{
                        "Name": "Armory",
                        "UrlStub": "armory",
                        "Id": "4a102000-0000-3000-8000-1ebe10000000",
                        "ImageName": "H2_C02_THEARMORY.png"
                    }, {
                        "Name": "Cairo Station",
                        "UrlStub": "cairo",
                        "Id": "4a102000-0000-3000-8000-1ebe10000001",
                        "ImageName": "H2_C03_CARIOSTATION.png"
                    }, {
                        "Name": "Outskirts",
                        "UrlStub": "os",
                        "Id": "4a102000-0000-3000-8000-1ebe10000002",
                        "ImageName": "H2_C04_OUTSKIRTS.png"
                    }, {
                        "Name": "Metropolis",
                        "UrlStub": "metro",
                        "Id": "4a102000-0000-3000-8000-1ebe10000003",
                        "ImageName": "H2_C05_METROPOLIS.png"
                    }, {
                        "Name": "The Arbiter",
                        "UrlStub": "arbiter",
                        "Id": "4a102000-0000-3000-8000-1ebe10000004",
                        "ImageName": "H2_C06_THEARBITER.png"
                    }, {
                        "Name": "Oracle",
                        "UrlStub": "oracle",
                        "Id": "4a102000-0000-3000-8000-1ebe10000005",
                        "ImageName": "H2_C07_THEORACLE.png"
                    }, {
                        "Name": "Delta Halo",
                        "UrlStub": "dh",
                        "Id": "4a102000-0000-3000-8000-1ebe10000006",
                        "ImageName": "H2_C08_DELTAHALO.png"
                    }, {
                        "Name": "Regret",
                        "UrlStub": "regret",
                        "Id": "4a102000-0000-3000-8000-1ebe10000007",
                        "ImageName": "H2_C09_REGRET.png"
                    }, {
                        "Name": "Sacred Icon",
                        "UrlStub": "si",
                        "Id": "4a102000-0000-3000-8000-1ebe10000008",
                        "ImageName": "H2_C10_SACREDICON.png"
                    }, {
                        "Name": "Quarantine Zone",
                        "UrlStub": "qz",
                        "Id": "4a102000-0000-3000-8000-1ebe10000009",
                        "ImageName": "H2_C11_QUARANTINEZONE.png"
                    }, {
                        "Name": "Gravemind",
                        "UrlStub": "gravemind",
                        "Id": "4a102000-0000-3000-8000-1ebe10000010",
                        "ImageName": "H2_C12_GRAVEMIND.png"
                    }, {
                        "Name": "Uprising",
                        "UrlStub": "uprising",
                        "Id": "4a102000-0000-3000-8000-1ebe10000011",
                        "ImageName": "H2_C13_UPRISING.png"
                    }, {
                        "Name": "High Charity",
                        "UrlStub": "hc",
                        "Id": "4a102000-0000-3000-8000-1ebe10000012",
                        "ImageName": "H2_C14_HIGHCHARITY.png"
                    }, {
                        "Name": "The Great Journey",
                        "UrlStub": "tgj",
                        "Id": "4a102000-0000-3000-8000-1ebe10000013",
                        "ImageName": "H2_C15_THEGREATJOURNEY.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "4a102000-0000-3000-8000-1ebe100000f9",
                        "ImageName": "H2_FG.png"
                    }, {
                        "Name": "Full Game - With Armory",
                        "UrlStub": "fullgamearmory",
                        "Id": "4a102000-0000-3000-8000-1ebe100000fa",
                        "ImageName": "H2_FG.png"
                    }
                    ],
                    "Id": "4a102000-0000-3000-8000-000000000000",
                    "ImageName": "HALO2CLASSIC.png"
                }, {
                    "Name": "Halo 2 MCC",
                    "UrlStub": "h2a",
                    "RunnableSegments": [{
                        "Name": "The Armory - MCC",
                        "UrlStub": "armory",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000000",
                        "ImageName": "H2_C02_THEARMORY.png"
                    }, {
                        "Name": "Cairo Station - MCC",
                        "UrlStub": "cairo",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000001",
                        "ImageName": "H2_C03_CARIOSTATION.png"
                    }, {
                        "Name": "Outskirts - MCC",
                        "UrlStub": "os",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000002",
                        "ImageName": "H2_C04_OUTSKIRTS.png"
                    }, {
                        "Name": "Metropolis - MCC",
                        "UrlStub": "metro",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000003",
                        "ImageName": "H2_C05_METROPOLIS.png"
                    }, {
                        "Name": "The Arbiter - MCC",
                        "UrlStub": "arbiter",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000004",
                        "ImageName": "H2_C06_THEARBITER.png"
                    }, {
                        "Name": "Oracle - MCC",
                        "UrlStub": "oracle",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000005",
                        "ImageName": "H2_C07_THEORACLE.png"
                    }, {
                        "Name": "Delta Halo - MCC",
                        "UrlStub": "dh",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000006",
                        "ImageName": "H2_C08_DELTAHALO.png"
                    }, {
                        "Name": "Regret - MCC",
                        "UrlStub": "regret",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000007",
                        "ImageName": "H2_C09_REGRET.png"
                    }, {
                        "Name": "Sacred Icon - MCC",
                        "UrlStub": "si",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000008",
                        "ImageName": "H2_C10_SACREDICON.png"
                    }, {
                        "Name": "Quarantine Zone - MCC",
                        "UrlStub": "qz",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000009",
                        "ImageName": "H2_C11_QUARANTINEZONE.png"
                    }, {
                        "Name": "Gravemind - MCC",
                        "UrlStub": "gravemind",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000010",
                        "ImageName": "H2_C12_GRAVEMIND.png"
                    }, {
                        "Name": "Uprising - MCC",
                        "UrlStub": "uprising",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000011",
                        "ImageName": "H2_C13_UPRISING.png"
                    }, {
                        "Name": "High Charity - MCC",
                        "UrlStub": "hc",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000012",
                        "ImageName": "H2_C14_HIGHCHARITY.png"
                    }, {
                        "Name": "The Great Journey - MCC",
                        "UrlStub": "tgj",
                        "Id": "4a102a00-0000-3000-8000-1ebe10000013",
                        "ImageName": "H2_C15_THEGREATJOURNEY.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "4a102a00-0000-3000-8000-1ebe100000f9",
                        "ImageName": "H2_FG.png"
                    }, {
                        "Name": "Full Game - With Armory",
                        "UrlStub": "fullgamearmory",
                        "Id": "4a102a00-0000-3000-8000-1ebe100000fa",
                        "ImageName": "H2_FG.png"
                    }
                    ],
                    "Id": "4a102a00-0000-3000-8000-000000000000",
                    "ImageName": "HALO2.png"
                }, {
                    "Name": "Halo: Reach",
                    "UrlStub": "reach",
                    "RunnableSegments": [{
                        "Name": "Winter Contingency",
                        "UrlStub": "wc",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000000",
                        "ImageName": "HREACH_C02_WINTERCONTINGENCY.png"
                    }, {
                        "Name": "ONI: Sword Base",
                        "UrlStub": "oni",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000001",
                        "ImageName": "HREACH_C03_ONISWORDBASE.png"
                    }, {
                        "Name": "Nightfall",
                        "UrlStub": "nightfall",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000002",
                        "ImageName": "HREACH_C04_NIGHTFALL.png"
                    }, {
                        "Name": "Tip of the Spear",
                        "UrlStub": "tots",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000003",
                        "ImageName": "HREACH_C05_ONTHETOPOFTHESPEAR.png"
                    }, {
                        "Name": "Long Night of Solace",
                        "UrlStub": "lnos",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000004",
                        "ImageName": "HREACH_C06_LONGNIGHTOFSOLACE.png"
                    }, {
                        "Name": "Exodus",
                        "UrlStub": "exo",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000005",
                        "ImageName": "HREACH_C07_EXODUS.png"
                    }, {
                        "Name": "New Alexandria",
                        "UrlStub": "na",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000006",
                        "ImageName": "HREACH_C08_NEWALEXANDRIA.png"
                    }, {
                        "Name": "The Package",
                        "UrlStub": "package",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000007",
                        "ImageName": "HREACH_C09_THEPACKAGE.png"
                    }, {
                        "Name": "The Pillar of Autumn",
                        "UrlStub": "poa",
                        "Id": "4a102eac-0000-3000-8000-1ebe10000008",
                        "ImageName": "HREACH_C10_THEPILLAROFAUTUMN.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "4a102eac-0000-3000-8000-1ebe100000f9",
                        "ImageName": "HREACH_FG.png"
                    }
                    ],
                    "Id": "4a102eac-0000-3000-8000-000000000000",
                    "ImageName": "HALOREACH.png"
                }, {
                    "Name": "Halo 3",
                    "UrlStub": "h3",
                    "RunnableSegments": [{
                        "Name": "Sierra 117",
                        "UrlStub": "sierra",
                        "Id": "4a103000-0000-3000-8000-1ebe10000000",
                        "ImageName": "H3_C02_SIERRA117.png"
                    }, {
                        "Name": "Crow's Nest",
                        "UrlStub": "crows",
                        "Id": "4a103000-0000-3000-8000-1ebe10000001",
                        "ImageName": "H3_C03_CROW'SNEST.png"
                    }, {
                        "Name": "Tsavo Highway",
                        "UrlStub": "tsavo",
                        "Id": "4a103000-0000-3000-8000-1ebe10000002",
                        "ImageName": "H3_C04_TSAVOHIGHWAY.png"
                    }, {
                        "Name": "The Storm",
                        "UrlStub": "storm",
                        "Id": "4a103000-0000-3000-8000-1ebe10000003",
                        "ImageName": "H3_C05_THESTORM.png"
                    }, {
                        "Name": "Floodgate",
                        "UrlStub": "floodgate",
                        "Id": "4a103000-0000-3000-8000-1ebe10000004",
                        "ImageName": "H3_C06_FLOODGATE.png"
                    }, {
                        "Name": "The Ark",
                        "UrlStub": "ark",
                        "Id": "4a103000-0000-3000-8000-1ebe10000005",
                        "ImageName": "H3_C07_THEARK.png"
                    }, {
                        "Name": "The Covenant",
                        "UrlStub": "covenant",
                        "Id": "4a103000-0000-3000-8000-1ebe10000006",
                        "ImageName": "H3_C08_THECOVENANT.png"
                    }, {
                        "Name": "Cortana",
                        "UrlStub": "cortana",
                        "Id": "4a103000-0000-3000-8000-1ebe10000007",
                        "ImageName": "H3_C09_CORTANA.png"
                    }, {
                        "Name": "Halo",
                        "UrlStub": "halo",
                        "Id": "4a103000-0000-3000-8000-1ebe10000008",
                        "ImageName": "H3_C10_HALO3.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "4a103000-0000-3000-8000-1ebe100000f9",
                        "ImageName": "H3_FG.png"
                    }
                    ],
                    "Id": "4a103000-0000-3000-8000-000000000000",
                    "ImageName": "HALO3.png"
                }, {
                    "Name": "Halo 4",
                    "UrlStub": "h4",
                    "RunnableSegments": [{
                        "Name": "Dawn",
                        "UrlStub": "dawn",
                        "Id": "4a104000-0000-3000-8000-1ebe10000000",
                        "ImageName": "H4_C02_DAWN.png"
                    }, {
                        "Name": "Requiem",
                        "UrlStub": "requiem",
                        "Id": "4a104000-0000-3000-8000-1ebe10000001",
                        "ImageName": "H4_C03_REQUIEM.png"
                    }, {
                        "Name": "Forerunner",
                        "UrlStub": "forerunner",
                        "Id": "4a104000-0000-3000-8000-1ebe10000002",
                        "ImageName": "H4_C04_FORERUNNER.png"
                    }, {
                        "Name": "Infinity",
                        "UrlStub": "infinity",
                        "Id": "4a104000-0000-3000-8000-1ebe10000003",
                        "ImageName": "H4_C05_INFINITY.png"
                    }, {
                        "Name": "Reclaimer",
                        "UrlStub": "reclaimer",
                        "Id": "4a104000-0000-3000-8000-1ebe10000004",
                        "ImageName": "H4_C06_RECLAIMER.png"
                    }, {
                        "Name": "Shutdown",
                        "UrlStub": "shutdown",
                        "Id": "4a104000-0000-3000-8000-1ebe10000005",
                        "ImageName": "H4_C07_SHUTDOWN.png"
                    }, {
                        "Name": "Composer",
                        "UrlStub": "composer",
                        "Id": "4a104000-0000-3000-8000-1ebe10000006",
                        "ImageName": "H4_C08_COMPOSER.png"
                    }, {
                        "Name": "Midnight",
                        "UrlStub": "midnight",
                        "Id": "4a104000-0000-3000-8000-1ebe10000007",
                        "ImageName": "H4_C09_MIDNIGHT.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "4a104000-0000-3000-8000-1ebe100000f9",
                        "ImageName": "H4_FG.png"
                    }
                    ],
                    "Id": "4a104000-0000-3000-8000-000000000000",
                    "ImageName": "HALO4.png"
                }, {
                    "Name": "Halo 5",
                    "UrlStub": "h5",
                    "RunnableSegments": [{
                        "Name": "Osiris",
                        "UrlStub": "osiris",
                        "Id": "4a105000-0000-3000-8000-1ebe10000000",
                        "ImageName": "h5-1-osirispng.png"
                    }, {
                        "Name": "Blue Team",
                        "UrlStub": "blueteam",
                        "Id": "4a105000-0000-3000-8000-1ebe10000001",
                        "ImageName": "h5-2-blue-teampng.png"
                    }, {
                        "Name": "Glassed",
                        "UrlStub": "glassed",
                        "Id": "4a105000-0000-3000-8000-1ebe10000002",
                        "ImageName": "h5-3-glassedpng.png"
                    }, {
                        "Name": "Unconfirmed",
                        "UrlStub": "unconfirmed",
                        "Id": "4a105000-0000-3000-8000-1ebe10000003",
                        "ImageName": "h5-4-unconfirmedpng.png"
                    }, {
                        "Name": "Evacuation",
                        "UrlStub": "evac",
                        "Id": "4a105000-0000-3000-8000-1ebe10000004",
                        "ImageName": "h5-5-evacuationpng.png"
                    }, {
                        "Name": "Reunion",
                        "UrlStub": "reunion",
                        "Id": "4a105000-0000-3000-8000-1ebe10000005",
                        "ImageName": "h5-6-reunionpng.png"
                    }, {
                        "Name": "Swords of Sanghelios",
                        "UrlStub": "swords",
                        "Id": "4a105000-0000-3000-8000-1ebe10000006",
                        "ImageName": "h5-7-swords-of-sangheliospng.png"
                    }, {
                        "Name": "Enemy Lines",
                        "UrlStub": "el",
                        "Id": "4a105000-0000-3000-8000-1ebe10000007",
                        "ImageName": "h5-8-enemy-linespng.png"
                    }, {
                        "Name": "Battle of Sunaion",
                        "UrlStub": "bos",
                        "Id": "4a105000-0000-3000-8000-1ebe10000008",
                        "ImageName": "h5-9-battle-of-sunaionpng.png"
                    }, {
                        "Name": "Genesis",
                        "UrlStub": "genesis",
                        "Id": "4a105000-0000-3000-8000-1ebe10000009",
                        "ImageName": "h5-10-genesispng.png"
                    }, {
                        "Name": "The Breaking",
                        "UrlStub": "breaking",
                        "Id": "4a105000-0000-3000-8000-1ebe10000010",
                        "ImageName": "h5-11-the-breakingpng.png"
                    }, {
                        "Name": "Guardians",
                        "UrlStub": "guardians",
                        "Id": "4a105000-0000-3000-8000-1ebe10000011",
                        "ImageName": "h5-12-guardianspng.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "4a105000-0000-3000-8000-1ebe100000f9",
                        "ImageName": "H5_FG.png"
                    }
                    ],
                    "Id": "4a105000-0000-3000-8000-000000000000",
                    "ImageName": "Halo_Guardians.png"
                }, {
                    "Name": "Multi Game",
                    "UrlStub": "multi",
                    "RunnableSegments": [{
                        "Name": "Full Completion",
                        "UrlStub": "fullcompletion",
                        "Id": "384d5730-720a-448d-be2f-deb584be758f",
                        "ImageName": "CROSSGAMEPLAYLIST.png"
                    }
                    ],
                    "Id": "4a10a110-0000-3000-8000-000000000000",
                    "ImageName": "HALOX.png"
                }, {
                    "Name": "Halo CE",
                    "UrlStub": "hce",
                    "RunnableSegments": [{
                        "Name": "The Pillar of Autumn",
                        "UrlStub": "poa",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000000",
                        "ImageName": "H1_C01_PILLARAUTUMN.png"
                    }, {
                        "Name": "Halo",
                        "UrlStub": "halo",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000001",
                        "ImageName": "H1_C02_HALO.png"
                    }, {
                        "Name": "The Truth and Reconciliation",
                        "UrlStub": "tnr",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000002",
                        "ImageName": "H1_C03_THETRUTHANDRECONCILIATION.png"
                    }, {
                        "Name": "The Silent Cartographer",
                        "UrlStub": "sc",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000003",
                        "ImageName": "H1_C04_THESILENTCARTOGRAPHER.png"
                    }, {
                        "Name": "Assault on the Control Room",
                        "UrlStub": "aotcr",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000004",
                        "ImageName": "H1_C05_ASSAULTONTHECONTROLROOM.png"
                    }, {
                        "Name": "343 Guilty Spark",
                        "UrlStub": "343gs",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000005",
                        "ImageName": "H1_C06_GUILTYSPARK.png"
                    }, {
                        "Name": "The Library",
                        "UrlStub": "library",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000006",
                        "ImageName": "H1_C07_LIBRARY.png"
                    }, {
                        "Name": "Two Betrayals",
                        "UrlStub": "tb",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000007",
                        "ImageName": "H1_C08_TWOBETRAYALS.png"
                    }, {
                        "Name": "Keyes",
                        "UrlStub": "keyes",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000008",
                        "ImageName": "H1_C09_KEYES.png"
                    }, {
                        "Name": "The Maw",
                        "UrlStub": "maw",
                        "Id": "4a10ce00-0000-3000-8000-1ebe10000009",
                        "ImageName": "H1_C10_THEMAW.png"
                    }, {
                        "Name": "Full Game",
                        "UrlStub": "fullgame",
                        "Id": "4a10ce00-0000-3000-8000-1ebe100000f9",
                        "ImageName": "H1_FG.png"
                    }
                    ],
                    "Id": "4a10ce00-0000-3000-8000-000000000000",
                    "ImageName": "HALO1.png"
                }
                ]
            }
    }


</script>

<style lang="scss" scoped>
    @use "@/assets/haloruns_vars.scss" as *;
    
    .trophies {
        margin-left: 1rem;
    }

    .trophy-container {
        margin-right: 1rem;
    }

    .stat-category {
        
        margin: 10px;
        

        label {
            font-size: 1.5rem;
            text-align: center;
        }

        .stat-cards {
            display: flex;
            gap: 10px;
            justify-content: center;
            flex-wrap: wrap;

            .stat {
                font-family: 'Oswald';
                border: 1px solid white;
                padding: 17px;
                margin-bottom: 20px;
                background: rgba(255, 255, 255, 0.11);

                display: flex;
                flex-direction: column;
                align-content: center;

                .stat-number {
                    font-size: 20px;
                    
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .priorities-grid {
        display: grid;
        gap: 10px; 
        grid-template-rows: auto auto;
        grid-template-columns: 300px auto auto;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .game-card {
            grid-row: 1 / span 2
        }

        .il-card {
            grid-row-start: 1;
            grid-column: 2 / span 2;
        }

        .difficulty-card {
            grid-row-start: 2;
            grid-column: 2 / span 2;

            img {
                width: 96px;
                margin: 0 auto;
            }
        }

        .cat-card {
            grid-row-start: 3;
            grid-column-start: 2;
        }

        .extra-card {
            grid-row-start: 3;
            grid-column-start: 3;
        }
    }

    .img-title {
        position: relative;
        top: 25px;
        left: 10px;
        font-size: 15px;
        text-shadow:
            2px 2px 0 #000,
            -2px 2px 0 #000,
            -2px -2px 0 #000,
            2px -2px 0 #000;
        }
    
</style>
    