<template>
<div class="hr-tile">
    <ul class="breadcrumbs">
        <li class="breadcrumb-item">
            <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
            <span>Rules</span>
        </li>
    </ul>
</div>
<div class="rules hr-tile greeble-tr">
    
    <div class="columns is-multiline">
        <div class="column is-one-fifth is-full-mobile content">
            <h4 class="title is-4">Game Rules</h4>
            <ul class="list">
                <li v-for="game in games" :key="game.Id"><a :href="'#'+game.UrlStub" >{{game.Name}}</a></li>
            </ul>
        </div>
        <div class="column is-three-quarters is-full-mobile content">
            <h4 class="title is-4">Site Rules</h4>
            <Markdown :source="siteRules" class="rule-section"></Markdown>
        </div>
    </div>
    
</div>
<div class="rules hr-tile" v-for="game in games" :key="game.Id" :id="game.UrlStub">
    <h4 class="title is-4">{{game.Name}} Rules</h4>
    <Markdown v-if="game.Rules" :source="game.Rules" class="rule-section" :breaks="true"></Markdown>
    <template v-for="cat in game.Categories" :key="cat.Id">
        <div v-if="cat.Rules && !cat.IsExtension">
            <h1>{{cat.Name}} Specific</h1>
            <Markdown :source="cat.Rules" class="rule-section" :breaks="true"></Markdown>
        </div>
    </template>

    <div v-if="game.Categories.find(c => c.IsExtension)">
        <div class="collapsable-title">
            <h5 class="title is-5" v-collapsible:parent>Extras Rules</h5>
            <h6 class="subtitle is-7 mb-0">(Click to expand)</h6>
        </div>
        <div class="extras-container">
            <div v-for="cat in game.Categories" :key="cat.Id">
                <div v-if="cat.Rules && cat.IsExtension">
                    <h1>{{cat.Name}} Specific</h1>
                    <Markdown :source="cat.Rules" class="rule-section" :breaks="true"></Markdown>
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<script lang="ts">
import { Game } from "@/api";
import { GameStore } from "@/store/games";
import { Component, Vue } from "vue-facing-decorator";
import Markdown from 'vue3-markdown-it';
import Collapsible from "@/directives/Collapsible";

@Component({
    components: {
        Markdown
    },
    directives: {
        Collapsible
    }
})
export default class Rules extends Vue {
    public games: Game[] = new Array();
    public navigatedToAnchor = false;

    // todo: move to metadata or something
    public siteRules = `
- Users may submit under only one account, and only one user may submit under an account.

- Must not use any skulls from the menu.
- Individual level runs must not start with weapons carried over from previous missions.
- Loading core saves and custom checkpoints at any point during a run is not allowed. This includes during opening cutscenes for individual levels. Loading a checkpoint with a preferred seed before a run however, is allowed.

- No macros are allowed. This includes controllers with a "turbo" functionality.
- Inputs may be remapped however, they must be one-to-one.
- Mouse and keyboard to controller adapters (such as XIM adapters) are not allowed.
- No emulator runs are allowed, Xbox 360/One/Series Backwards Compatibility emulator is excluded from this.

- Video submissions are required for all runs that are a top 5 placing or give at least 50% of max points.
- Runs outside the top 20 times, or runs that do not meet the above requirements do not require video proof however, it is strongly recommended.
- Twitch VOD submissions must be highlighted.
- Video submissions must be first person perspective and should, if possible, clearly and accurately show execution of the run.
- Co-op submissions that will give at least 50% of max points or a top 5 placing must include videos for all player viewpoints.
- Submitted gameplay must not be obscured by stream elements (webcam/livesplit/overlay). Health bars, ammo counters, reticle, radar, status messages (checkpoint... done), and MCC timer (if required for submission) must be clearly visible and unobstructed at all times.`

    async created() {
        this.games = (await GameStore.get()).games;
    }

    async updated() {
        if(window.location.hash && !this.navigatedToAnchor) {
            let elem = document.getElementById(window.location.hash.substr(1));
            if(elem) {
                window.requestAnimationFrame(() => {
                    elem.scrollIntoView();
                    this.navigatedToAnchor = true;
                });
            }
        }
    }
}

</script>

<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.collapsable-title {
    margin-bottom: 1rem;
}

.extras-container {
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 2px solid $border-color
}

.content .title {
    margin-bottom: 0;
}

</style>
