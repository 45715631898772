/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DifficultyDto } from '../models/DifficultyDto';
import { request as __request } from '../core/request';

export class DifficultiesService {

    /**
     * @returns DifficultyDto OK
     * @throws ApiError
     */
    public static async getAllDifficulties(): Promise<Array<DifficultyDto>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Difficulties`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns DifficultyDto OK
     * @throws ApiError
     */
    public static async createDifficulty(
requestBody?: DifficultyDto,
): Promise<DifficultyDto> {
        const result = await __request({
            method: 'POST',
            path: `/api/Difficulties`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns DifficultyDto OK
     * @throws ApiError
     */
    public static async updateDifficulty(
requestBody?: DifficultyDto,
): Promise<DifficultyDto> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Difficulties`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param identifier 
     * @returns DifficultyDto OK
     * @throws ApiError
     */
    public static async getDifficulty(
identifier: string,
): Promise<DifficultyDto> {
        const result = await __request({
            method: 'GET',
            path: `/api/Difficulties/${identifier}`,
        });
        return result.body;
    }

}